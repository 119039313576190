import React from 'react'
import '../components/css/layout.css'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../components/css/policy.css'
import digiPadalaCustomer from 'images/digipadala-customer.png'
import { Image } from 'react-bootstrap'
import Seo from '../components/Seo'

const DigiPadalaCustomer = () => (
  <div>
    <Seo
      title="Digipay PH | DigiPadalaCustomer"
      description="Digipay is a digital payments and financial services platform with
a mobile wallet, an industry leading biller ecosystem,
and an interoperable backend."
      keywords="digipay.ph/DigiPadalaCustomer/,DigiPadalaCustomer, DigiPadala, customer, Requirements, Customer, Customer Requirements, Transfer, Rates, Transfer Rates, Domestic, Remittance, Domestic Remittance"
    />
    <Header />
    <div id="digipadala-customer-section">
      <Image src={digiPadalaCustomer} alt="digipadala-customer" width="100%" />
    </div>
    <Footer></Footer>
  </div>
)
export default DigiPadalaCustomer
