import React from 'react'
import { Helmet } from 'react-helmet'

export default function Seo({ title, keywords, description }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta name="robots" content="index,follow" />
      <meta name="og:type" content="map" />
      <meta name="og:url" content="https://www.digipay.ph/" />
      <meta name="og:title" content={title} />
      <meta name="og:image" content />
      <meta name="og:image" content />
      <meta name="og:description" content={description} />
      <meta name="og:site_name" content="Digipay PH" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@DigipayPH" />
      <meta name="twitter:url" content="https://www.digipay.ph/" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content />
      <meta name="twitter:image:alt" content />
    </Helmet>
  )
}
